import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  MenuFilesDisplay,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class ApplyOnlinePage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Apply Online"}
              tagline={""}
              images={[
                "https://fisherman.gumlet.io/public/zipsdriveincheney/AF1QipNPxGgr8fkmOjUsxCFQAAcbGq7kZFeZHu6U8_ICs1600-w1140-h1503_I0Oojma.jpeg",
              ]}
              ctas={[
                {
                  text: "Cheney",
                  link: "mailto:zipscheney@gmail.com",
                  internal: false,
                },
                {
                  text: "Mead",
                  link: "mailto:zipsmead@gmail.com",
                  internal: false,
                },
                {
                  text: "Deer Park",
                  link: "mailto:zipsdeerpark@gmail.com",
                  internal: false,
                },
                {
                  text: "Spokane Valley - 6505 E Sprague Ave",
                  link: "mailto:zips6505sprague@gmail.com",
                  internal: false,
                },
                {
                  text: "Spokane Valley - 11222 E Sprague Ave",
                  link: "mailto:zipsvalley@gmail.com",
                  internal: false,
                },
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={16}>
              <MenuFilesDisplay
                files={[
                  {
                    url: "https://fisherman.gumlet.io/public/zipsdriveincheney/Zips_Application.pdf",
                    filename: "Application for Employment",
                  },
                ]}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "Apply Online" }) {
      title
      components {
        fastId
        order
        files {
          file
        }
      }
    }
  }
`;
